/* Available themes: https://github.com/PrismJS/prism-themes */
@import 'prism-themes/themes/prism-material-dark.min.css';

pre {
  @apply overflow-auto;
}

code {
  @apply px-2 py-1;
  background: var(--tw-prose-pre-bg);
  color: var(--tw-prose-pre-code);
}

pre[class*='language-'] {
  @apply my-8 rounded-none font-mono text-base;
  background: var(--tw-prose-pre-bg);
}

pre[class*='language-'] code[class*='language-'] {
  @apply whitespace-pre-wrap bg-transparent;
}

/**
 * Adds code highlighting & line numbers
 * Inspired by gatsby remark prism - https://www.gatsbyjs.com/plugins/gatsby-remark-prismjs/
 * 1. Make the element just wide enough to fit its content.
 * 2. Always fill the visible space in .code-highlight.
 */

.code-highlight {
  float: left; /* 1 */
  min-width: 100%; /* 2 */
}

.code-line {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-left-width: 4px;
  border-left-color: rgba(31, 41, 55, 0); /* Set code block color */
}

.code-line.inserted {
  background-color: rgba(16, 185, 129, 0.2); /* Set inserted line (+) color */
}

.code-line.deleted {
  background-color: rgba(239, 68, 68, 0.2); /* Set deleted line (-) color */
}

.highlight-line {
  margin-left: -16px;
  margin-right: -16px;
  background-color: rgba(55, 65, 81, 0.5); /* Set highlight bg color */
  border-left-width: 4px;
  border-left-color: rgb(59, 130, 246); /* Set highlight accent border color */
}

.line-number::before {
  display: inline-block;
  width: 1rem;
  text-align: right;
  margin-right: 16px;
  margin-left: -8px;
  color: rgba(156, 163, 175, 0.3); /* Line number color */
  content: attr(line);
}
